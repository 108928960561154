import React, { useState } from "react";
import { Chart } from "react-google-charts";
import './App.css';

export const data = [
  ["Mês", "Valor total Acumulado", "Valor Investido"],
  [0, 0, 0],

];

export const options = {
  chart: {
    title: "Linha do tempo em investimento",
    subtitle: "Valor acumulado",
  },
};

function App() {


  const [monthContribution, setMonthContribution] = useState(0);
  const [months, setMonths] = useState(0);
  const [years, setYears] = useState(0);
  const [rateFeesMonth, setRateFeesMonth] = useState(0);
  const [rateSample, setRateSample] = useState(0)
  const [amount, setAmount] = useState(0)
  const [amountInvested, setAmountInvested] = useState(0)

  const calculate = () => {
    data.splice(0, data.length)
    data.push(["Mês", "Valor total Acumulado", "Valor Investido"])

    let amount = 0
    let amountInvested = 0

    for (let i = 1; i <= months; i++) {
      amount = (amount + parseFloat(monthContribution)) * parseFloat(rateFeesMonth);
      amountInvested = (amountInvested) + parseFloat(monthContribution);
      data.push([i, amount, amountInvested])
    }
    setAmount(amount);
    setAmountInvested(amountInvested)
  }

  const handleRate = (value) => {
    setRateFeesMonth(((value) / 100) + 1)
    setRateSample(value)
  }
  const handleMonth = (value) => {
    setMonths(value)
    setYears(value / 12)
  }
  const handleYears = (value) => {
    setMonths(value * 12)
    setYears(value)
  }

  return (
    <div className="App">
      <h1>CALCULE AQUI SEUS INVESTIMENTOS</h1>
      <section className="viewInput">
        <div>
          <p>Investindo R$</p>
          <input type="number" name="aporteMensal" placeholder="100,00" value={monthContribution} onChange={(e) => setMonthContribution(e.target.value)} />
          <p>em aportes mensais,</p>
        </div>
        <div>
          <p>durante</p>
          <input type="number" name="qtdMeses" placeholder="180" value={months} onChange={(e) => handleMonth(e.target.value)} />
          <p>meses, ou</p>
          <input type="number" name="years" placeholder="15" value={years} onChange={(e) => handleYears(e.target.value)} />
          <p>anos,</p>
        </div>
        <div>
          <p>com uma taxa de juros mensais de</p>
          <input type="number" name="taxaDeJurosMes" placeholder="0" value={rateSample} onChange={(e) => handleRate(e.target.value)} />
          <p>%.</p>
        </div>
      </section>
      <button onClick={() => calculate()}>Calcular</button>
      <article class="viewResult">
        <h3 class="result">Valor investido: R${(amountInvested).toFixed(2)}</h3>
        <h3 class="result">Resultado final: R${(amount).toFixed(2)}</h3>
      </article>
      <section id="graphics">
        <Chart

          chartType="Line"
          width="100%"
          height="400px"
          data={data}
          options={options}

        />
      </section>

      <span>Desenvolvido por <a href="https://github.com/gabrielholz">github.com/gabrielholz</a></span>

    </div>
  );
}

export default App;
